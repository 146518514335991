/* eslint-disable */
import { useCallback, useState, useRef, useEffect } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Button,
  Popover,
  List,
  ListItem,
  CircularProgress,
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'

import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import JSZip from 'jszip'
import ReModal from '../../components/Modal/Modal'
import { showNotification } from '../Notifications/NotificationActionCreator'
// image
import TrickWithUnder from '../assets/images/TrickwithUnderline.svg'
import { Backup } from '@material-ui/icons'
// style
import './NbrStyles/Style.scss'
import {
  fileUploaded,
  tcinsVerification,
} from '../store/NationalBrand/ActionCreator'
import { CircularProgressWithLabel, style } from './NbrStyles/NBrStyle'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NavigateNextIcon from '@material-ui/icons/ChevronRight'

const setReloadStopper = (on) => {
  window.onbeforeunload = function () {
    return on
  }
}
const NBrAddAssets = ({
  openAddAssets,
  setAddAssetsModal,
  title,
  fetchList,
  projectId,
}) => {
  const dispatch = useDispatch()
  const [folderName, setFolderName] = useState('')
  const [progressOfChunks, setProgressOfChunks] = useState(0)
  const [findError, setFindError] = useState(false)
  const [findUploadedFiles, setFilesUploaded] = useState(null)
  const [uploadFolderList, setUploadedFolderList] = useState([])
  const [uploadedTcinList, setUploadedTcinList] = useState([])
  const [showVerificationList, setShowVerificationList] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [reportData, setReportData] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [uploadReadyFiles, setUploadReadyFiles] = useState([])
  const [folderUploaded, setFolderUploaded] = useState(false)

  const {
    verficationTcinList = [],
    verficationTcinFailed,
    totalTcins = 0,
    showVerificationList: storedShowVerificationList,
  } = useSelector((state) => state.nationalBrand)
  const [showList, setShowList] = useState(false)
  const listRef = useRef(null)
  const rightIconRef = useRef(null)
  const downIconRef = useRef(null)

  //checking for 2d and 3d
  const check2dOr3d = (string) => {
    let booltoCheck2dor3d = true
    let str = string.toLowerCase()
    if (str !== '3d' || str !== '2d') {
      booltoCheck2dor3d = false
    }
    if (str === '3d') {
      booltoCheck2dor3d = true
    }
    if (str === '2d') {
      booltoCheck2dor3d = true
    }
    return booltoCheck2dor3d
  }

  // Upload Assets
  const initUploadFile = (file, name) => {
    setReloadStopper()
    if (!file) {
      return false
    }
    setProgressOfChunks(0)
    const envUrl =
      apiConfig.task.uploadApi + '/chunk_uploads/start?key=' + apiConfig.apiKey
    axios
      .post(envUrl, {
        targets: ['INTERNAL'],
        original_file_name: name,
        job_context_id: projectId,
        job_context_id_type: 'GGA_PROJECT_ID',
      })
      .then((res) => {
        setProgressOfChunks(1)
        chunkStart(file, name, res.data.upload_id)
      })
      .catch((err) => {
        setProgressOfChunks(0)
        setIsUploading(false)
        setAddAssetsModal(false)
        setFolderName('')
        setFindError(err)
        setReloadStopper()
        dispatch(
          showNotification(
            true,
            'Something went wront !! Please try after some time',
            'error'
          )
        )
      })
  }

  //chunk of file start
  const chunkStart = async (file, name, upload_id) => {
    const chunkSize = 2 * 1024 * 1024 // 2mb
    const sizeOfFile = file.size
    const numberOfChunks = Math.ceil(sizeOfFile / chunkSize)
    const chunksPromise = []
    const envUrl =
      apiConfig.task.uploadApi + '/chunk_uploads?key=' + apiConfig.apiKey
    const CancelToken = axios.CancelToken.source()
    let promiseCount = numberOfChunks
    setReloadStopper('stop')
    for (let i = 0; i < numberOfChunks; i++) {
      const chunkedFormData = new FormData()
      const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize)
      chunkedFormData.append(
        'chunkUploadRequest',
        new Blob(
          [
            JSON.stringify({
              upload_id: upload_id,
              file_name: name,
              chunk_number: i,
              total_number_of_chunks: numberOfChunks,
              chunk_size: chunkSize,
              total_file_size: sizeOfFile,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      )
      chunkedFormData.append('file', chunk)
      chunksPromise.push(
        () =>
          new Promise((resolve) =>
            axios
              .post(envUrl, chunkedFormData, {
                cancelToken: CancelToken.token,
              })
              .then((res) => {
                const percentageComplete = ((i + 1) / numberOfChunks) * 100 - 1
                setProgressOfChunks(percentageComplete)
                resolve(res)
              })
              .catch((error) => {
                setReloadStopper()
                setIsUploading(false)
                setFindError(true)
                setProgressOfChunks(19)
                dispatch(
                  showNotification(
                    true,
                    'Something went wront !! Please try after some time',
                    'error'
                  )
                )
              })
          )
      )
    }
    Promise.allConcurrent(promiseCount)(chunksPromise).then((res) => {
      endUpload(
        res,
        name,
        upload_id,
        chunkSize,
        numberOfChunks,
        sizeOfFile,
        CancelToken
      )
    })
  }

  //end Upload
  const endUpload = (
    res,
    name,
    upload_id,
    chunkSize,
    numberOfChunks,
    sizeOfFile,
    CancelToken
  ) => {
    const envUrl =
      apiConfig.task.uploadApi + '/chunk_uploads/end?key=' + apiConfig.apiKey
    axios
      .post(
        envUrl,
        {
          upload_id: upload_id,
          file_name: name,
          targets: ['INTERNAL'],
          total_number_of_chunks: numberOfChunks,
          chunk_size: chunkSize,
          total_file_size: sizeOfFile,
          content_type: '*',
          metadata: '{"key": "value"}',
        },
        {
          cancelToken: CancelToken.token,
        }
      )
      .then((res) => {
        setReloadStopper()
        if (res.job_id === '') {
          throw new Error('Job Id not present')
        }

        axios
          .post(apiConfig.nationalBrand.processFile, {
            project_uuid: projectId,
            file_ref_ids: [upload_id],
          })
          .then((res) => {
            setIsUploading(false)
            setProgressOfChunks(100)
            fetchList()
            setIsUploading(false)
            setFolderUploaded(true)
            dispatch(
              showNotification(true, `Files Uploaded Successfully`, 'success')
            )
          })
          .catch((err) => {
            setReloadStopper()
            setIsUploading(false)

            dispatch(
              showNotification(
                true,
                'Something went wront !! Please try after some time',
                'error'
              )
            )
            setFindError(true)
            setProgressOfChunks(27)
          })
      })
  }

  // for Ziping Files
  const zipfiles = (fileTozip, name) => {
    const zip = new JSZip()
    // compassing the zip
    fileTozip.forEach((file) => {
      zip.file(file.path, file, { binary: true })
    })
    zip.generateAsync({ type: 'blob' }).then((content) => {
      const exportFilename = `${name}.zip`
      initUploadFile(content, exportFilename)
    })
  }

  //dropping the files in upload
  const onDrop = useCallback((acceptedFiles) => {
    // first folder should be same
    // path check --- 2d or 3d
    // tcin check -- should be 8 digit number
    let errorWrn = (
      <>
        The files you are trying to upload do not follow the correct structure.
        Please refer to the help section and access the onboarding document for
        process flow guidelines on how to upload assets. If further issues
        persist, please reach out to to support section referenced in the
        document.
      </>
    )
    if (acceptedFiles.length === 0) {
      dispatch(showNotification(true, errorWrn, 'error'))
      return
    }
    let pathInfo = []
    if (acceptedFiles.length > 0) {
      acceptedFiles.map((item) => {
        return pathInfo.push(item)
      })
    }
    //Getting name
    let name = ''
    let checkFiles = []
    let errorFlag = false
    let tcinCheck = []
    pathInfo.map((names) => {
      let folderPath = names.path.split('/')
      //second index is folder name
      name = folderPath[1]
      // Verify 2d/3d
      let booltoCheck2dor3d = check2dOr3d(folderPath[2])
      if (booltoCheck2dor3d === false) {
        dispatch(showNotification(true, errorWrn, 'error'))
        errorFlag = true
        return false
      }

      // tcin length
      let tcinCheckInfolder = Number(folderPath[3])
      if (
        `${tcinCheckInfolder}`.length < 8 ||
        `${tcinCheckInfolder}`.length >= 12
      ) {
        dispatch(showNotification(true, errorWrn, 'error'))
        errorFlag = true
        return false
      }

      //checking tcin does not contains any word or so
      if (isNaN(tcinCheckInfolder)) {
        dispatch(showNotification(true, errorWrn, 'error'))
        errorFlag = true
        return false
      }
      checkFiles.push(names)
      if (tcinCheck.indexOf(tcinCheckInfolder) === -1) {
        tcinCheck.push(tcinCheckInfolder)
      }
    })

    if (!errorFlag) {
      setIsVerifying(true)
      setFolderName(name)
      setUploadedTcinList(tcinCheck)
      dispatch(tcinsVerification(tcinCheck, projectId))
      setUploadReadyFiles(checkFiles)
    }
  }, [])

  useEffect(() => {
    setIsVerifying(false)
    setShowVerificationList(storedShowVerificationList)
    let reportData = []
    for (let index = 0; index < uploadedTcinList.length; index++) {
      const tcin = uploadedTcinList[index]
      const tcinIndex = verficationTcinList.findIndex(
        (failedTcin) => failedTcin == tcin
      )
      const obj = {
        Tcin: tcin,
        'Tcin Present in the project': tcinIndex > -1 ? 'No' : 'Yes',
      }
      reportData.push(obj)
    }
    setReportData(() => [...reportData])
  }, [verficationTcinList, verficationTcinFailed, storedShowVerificationList])

  useEffect(() => {
    return () => {
      setIsVerifying(false)
      setShowVerificationList(false)
      dispatch(tcinsVerification([]))
    }
  }, [])

  // drop
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop })

  // by closing the assets modal
  const handleCloseAddAssets = () => {
    setFolderName('')
    setAddAssetsModal(false)
    setProgressOfChunks(0)
    setReloadStopper()
  }

  const uploadFolder = () => {
    setIsUploading(true)
    zipfiles(uploadReadyFiles, folderName)
  }

  //upload history
  const handleHistoryAssets = (event) => {
    setFilesUploaded(event.currentTarget)
    setShowList((bool) => !bool)

    let success = (response) => {
      if (response.status !== 200 && response.includes(500)) {
        dispatch(
          showNotification(
            true,
            `Not available right now!! try to upload assets`,
            'success'
          )
        )
      }
      if (response.status === 200) {
        if (response.data && response.data.length > 0) {
          let arr = [...response.data]
          listRef.current.style.display = 'block'
          listRef.current.classList.add('animate-upload-history')
          setUploadedFolderList(arr)
        } else {
          setFilesUploaded(null)
          dispatch(showNotification(true, 'No History Available', 'error'))
        }
      }
    }
    dispatch(fileUploaded(projectId, success))
  }

  const downloadFormList = (item) => {
    if (item.original_file_name && item.job_id) {
      let agency_file_name = item.original_file_name
      let jobId = item.job_id
      let filePath = `${apiConfig.task.fileDownload}/${jobId}?key=${apiConfig.apiKey}&file_name=${agency_file_name}&is_download=true`
      window.location.assign(filePath)
    } else {
      dispatch(
        showNotification(
          true,
          'Something went wront !! Please try after some time',
          'error'
        )
      )
    }
  }

  const conditionForProgress = isUploading || isVerifying

  const showUpload =
    !showVerificationList && !isVerifying && !isUploading && !folderUploaded

  const progressCondition =
    !showVerificationList &&
    !isUploading &&
    !folderUploaded &&
    (folderName.length > 0 || isVerifying || isUploading)

  return (
    openAddAssets && (
      <ReModal open={openAddAssets}>
        <Box sx={style} className="Add-Tcin-modal Add-Asset-Modal">
          {/* Heading--------- */}
          <div className="main-contain">
            <Typography id="modal-modal-title" variant="h6" component="h6">
              {`Upload Assets to Project (${title})`}
            </Typography>
            <IconButton
              onClick={handleCloseAddAssets}
              // disabled={conditionModalCloseAfterProcessStart}
              style={{ padding: 0 }}
            >
              <Clear />
            </IconButton>
          </div>
          <div style={{ marginTop: '20px' }}>
            <img
              src={require('../assets/images/File_upload_format.svg')}
              alt="fileformat"
            />
          </div>
          {folderUploaded && (
            <div className="success-upload">
              <span className="bold-text">{`${folderName}`}</span>
              <div className="upload-assets-green">
                <p className="upload-complete-green">upload complete</p>{' '}
                <img src={TrickWithUnder} alt="TrickWithUnderline" />
              </div>
            </div>
          )}
          <div className="main-contain upload-view-link">
            <p>
              {folderUploaded
                ? 'Upload summary'
                : showVerificationList
                ? `File validation summary (${folderName})`
                : 'Upload Asset Inputs'}
            </p>

            {/* <Button
                onClick={(event) => event.stopPropagation()}
                disabled={true}
                className="view-format"
              >
                View folder format
              </Button> */}
          </div>
          {/*Upload files----------------------------*/}
          {showUpload && (
            <section
              className={
                isDragActive ? 'is-drag-active container' : 'container'
              }
            >
              <div
                {...getRootProps({
                  onClick: (event) => event.stopPropagation(),
                  onDoubleClick: (event) => event.stopPropagation(),
                })}
                className="upload-assets-inner-container"
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div className="Upload-assets">
                    <p>Drag And Drop files here ...</p>
                  </div>
                ) : (
                  <div className="Upload-assets">
                    <Backup />
                    Drag And Drop folder to upload
                  </div>
                )}
              </div>
            </section>
          )}
          {/* -------------progress and foldername--------------------- */}
          {isUploading && (
            <div className="upload-file-Names folder-upload">
              {`Uploading ${folderName}`}
              {conditionForProgress && <CircularProgress />}
            </div>
          )}

          {progressCondition ? (
            <div className="upload-file-Names folder-upload">
              {isUploading
                ? 'Uploading'
                : isVerifying
                ? 'Verifying'
                : folderName}{' '}
              {conditionForProgress && <CircularProgress />}
            </div>
          ) : null}
          {showVerificationList && !isVerifying && !isUploading && (
            <div className="response-container">
              <div className="inline-response-container">
                <div>
                  <p>Uploaded TCINs which are part of the project</p>
                  <p>Uploaded TCINs which are not part of the project</p>
                  <p>Total TCINs uploaded through the file</p>
                </div>
                <div>
                  <p>
                    {uploadedTcinList?.length - verficationTcinList?.length}
                  </p>
                  <p>{verficationTcinList?.length}</p>
                  <p>{uploadedTcinList?.length}</p>
                </div>
              </div>
            </div>
          )}
          {/** ------------Message--------- */}
          {((showVerificationList && !isUploading) || folderUploaded) && (
            <div
              className={`validation-message ${
                verficationTcinList.length
                  ? folderUploaded
                    ? 'validation-warning'
                    : 'validation-error'
                  : 'validation-success'
              }`}
            >
              {verficationTcinList?.length ? (
                verficationTcinList?.length === uploadedTcinList?.length ? (
                  <>
                    <>
                      <span className="bold-text">
                        {verficationTcinList?.length}
                      </span>{' '}
                      of{' '}
                      <span className="bold-text">
                        {uploadedTcinList?.length}
                      </span>
                      &nbsp; Files do not have corresponding TCINs in the
                      Project. Please add atleast one valid TCIN to upload.
                    </>
                    <br />
                    <br />
                    You can download a detailed report of the validation below
                  </>
                ) : folderUploaded ? (
                  <>
                    <span className="bold-text">
                      {verficationTcinList?.length}
                    </span>{' '}
                    of{' '}
                    <span className="bold-text">
                      {uploadedTcinList?.length}
                    </span>{' '}
                    &nbsp;were not uploaded due to no corresponding TCINs in the
                    Project. Please add the TCINs and upload again.
                  </>
                ) : (
                  <>
                    <>
                      <span className="bold-text">
                        {verficationTcinList?.length}
                      </span>{' '}
                      of{' '}
                      <span className="bold-text">
                        {uploadedTcinList?.length}
                      </span>
                      &nbsp; Files do not have corresponding TCINs in the
                      Project and will be ignored during upload. Do you wish to
                      upload anyway?.
                    </>
                    <br />
                    <br />
                    You can download a detailed report of the validation below
                  </>
                )
              ) : folderUploaded ? (
                <>
                  <>
                    <span className="bold-text">
                      {uploadedTcinList?.length}
                    </span>{' '}
                    of{' '}
                    <span className="bold-text">
                      {uploadedTcinList?.length}
                    </span>
                    &nbsp;Files were uploaded successfully to corresponding
                    TCINs in the Project.
                  </>
                </>
              ) : (
                <>
                  <span className="bold-text">{uploadedTcinList?.length}</span>{' '}
                  of{' '}
                  <span className="bold-text">{uploadedTcinList?.length}</span>
                  &nbsp; Files have corresponding TCINs in the Project.
                  <br />
                  <br />
                  You can download a detailed report of the validation below
                </>
              )}
            </div>
          )}
          {/** ------ Download Link */}
          {showVerificationList && !isUploading && (
            <div className="download-link">
              <CSVLink
                target="_blank"
                data={reportData}
                filename={`Report.csv`}
              >
                Download Report
              </CSVLink>
            </div>
          )}
          {/* -------------Button--------------------- */}
          <div className="upload-assets">
            <Button
              className="btn-upload-history"
              onClick={handleHistoryAssets}
            >
              {showList && <ExpandMoreIcon ref={downIconRef} />}
              {!showList && <NavigateNextIcon ref={rightIconRef} />}{' '}
              <>Upload History</>
            </Button>

            {showVerificationList && (
              <Button
                className={`${isUploading ? '' : 'btn-active'}`}
                onClick={
                  folderUploaded ||
                  verficationTcinList?.length === uploadedTcinList?.length
                    ? handleCloseAddAssets
                    : uploadFolder
                }
                disabled={isUploading}
              >
                {folderUploaded
                  ? 'Done'
                  : verficationTcinList?.length
                  ? verficationTcinList?.length === uploadedTcinList?.length
                    ? 'Close'
                    : 'Upload anyway'
                  : 'Upload'}
              </Button>
            )}
          </div>
          {showList && (
            <Box className="upload-history-list" ref={listRef}>
              <Box className="upload-history-item"></Box>
              {uploadFolderList.length > 0 &&
                uploadFolderList.map((item, index) => {
                  return (
                    <Box className="upload-history-item">
                      <List className="list-folder-item" key={index}>
                        <ListItem
                          style={{ padding: 0 }}
                          onClick={() => downloadFormList(item)}
                        >
                          {item.original_file_name}
                        </ListItem>
                      </List>
                    </Box>
                  )
                })}
            </Box>
          )}
        </Box>
      </ReModal>
    )
  )
}

export default NBrAddAssets
