import { useState } from 'react'
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { apiConfig } from './../Lib/config'
import { useDispatch } from 'react-redux'
import { showNotification } from '../../../app/Notifications/NotificationActionCreator'
import { isTPlus } from '../../../app/Auth/Auth'
import ErrorIcon from '@mui/icons-material/Error'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    margin: '20px auto',
  },
  cardContent: {
    backgroundColor: '#fffce3',
  },
  actionText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  radioGroup: {
    margin: theme.spacing(2, 1.5, 1),
  },
  form: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    background: '#ffffff',
    borderRadius: '1px',
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: '10px',
    textTransform: 'none',
  },
  submitLoading: {
    width: '20px !important',
    height: '20px !important',
    marginLeft: theme.spacing(0.5),
    color: '#ffffff',
    marginTop: theme.spacing(2),
  },
}))

const buttonStyles = (borderColor, textColor) => ({
  border: `1px solid ${borderColor}`,
  borderRadius: '8px',
  padding: '6px 12px',
  color: textColor,
  minWidth: '80px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiTypography-root': {
    fontSize: '14px',
    color: textColor,
    marginLeft: '6px',
    fontWeight: 500,
  },
})

export function ReviewTask({
  projectId,
  taskId,
  tcin,
  phaseId,
  updateTcinList = () => {},
}) {
  const classes = useStyles()
  const [actionType, setActionType] = useState('')
  const [loading, setLoading] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [error, setError] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()

  const handleReviewTask = async () => {
    if (!actionType) {
      dispatch(showNotification(true, 'Please select an action', 'error'))
      return
    }
    if (actionType === 'Reject' && !rejectionReason.trim()) {
      setError(true)
      return
    }
    setLoading(true)
    const postData = {
      project_tcin_task: [{ project_uuid: projectId, task_uuid: taskId, tcin }],
      phase_id: phaseId || 'RTA',
      status_id: actionType === 'Accept' ? 'APPROVED' : 'REJECT_AND_REASSIGN',
      target_plus_reason_for_rejection:
        actionType === 'Reject' ? rejectionReason : '',
      is_target_plus_vendor: isTPlus,
    }
    try {
      await axios.put(apiConfig.threeDViewer.reviewTask, postData)
      updateTcinList({
        tcin,
        status_id: actionType === 'Accept' ? 'Approved' : 'Rework',
      })
      dispatch(showNotification(true, 'Status changed successfully', 'success'))
      setSubmitted(true)
    } catch (error) {
      dispatch(showNotification(true, 'Unable to change the status', 'error'))
    } finally {
      setLoading(false)
    }
  }

  const handleRadioChange = (event) => {
    setActionType(event.target.value)
    setError(false)
  }

  const renderRadioButton = (value, label, borderColor, textColor) => (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            padding: '2px 0',
            '& .MuiSvgIcon-root': { fontSize: 18 },
            '&.Mui-checked': { color: textColor },
          }}
        />
      }
      label={label}
      sx={buttonStyles(borderColor, textColor)}
    />
  )

  if (submitted) {
    return null
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.actionText}
        >
          <ErrorIcon /> Action required!
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          The model creation is complete and ready to be reviewed.
        </Typography>
        <RadioGroup
          value={actionType}
          onChange={handleRadioChange}
          className={classes.radioGroup}
        >
          <Box display="flex" gap={1}>
            {renderRadioButton('Accept', 'Accept', '#3cb463', '#3cb463')}
            {renderRadioButton('Reject', 'Reject', '#d56c15', '#d56c15')}
          </Box>
        </RadioGroup>
        {actionType === 'Reject' && (
          <Box component="form" className={classes.form}>
            <Typography variant="body1">
              Provide reason<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              required
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              placeholder="Please provide the reason for rejection"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              error={error}
              helperText={error ? 'Rejection reason is required' : ''}
              FormHelperTextProps={{
                style: { marginLeft: 0, backgroundColor: '#fffce3' },
              }}
              className={classes.textField}
            />
          </Box>
        )}
        {loading ? (
          <CircularProgress className={classes.submitLoading} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: '10px',
              borderRadius: '6px',
              textTransform: 'none',
            }}
            onClick={handleReviewTask}
            disabled={loading}
          >
            Submit
          </Button>
        )}
      </CardContent>
    </Card>
  )
}
